import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledImpactCards = styled.div`
  background-color: ${Colors.grayLight};
  padding-top: ${Rem(35)};
`;

export const StyledImpactCardsCards = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledImpactCardsCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-basis: 50%;
  padding-bottom: ${Rem(35)};

  @media (${BP.ipad}) {
    flex-basis: 25%;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const StyledImpactCardsCardIcon = styled.div`
  width: ${Rem(70)};
  height: ${Rem(66)};
  background-image: url('${(props) => props.bg}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: ${Rem(16)};
  
  @media (${BP.ipad}) {
    margin-bottom: 0;
  }
`;

export const StyledImpactCardsCardInfo = styled.div`
  text-align: center;

  @media (${BP.ipad}) {
    text-align: left;
    padding-left: ${Rem(8)};
  }
`;

export const StyledImpactCardsCardText = styled.div`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
`;

export const StyledImpactCardsCardValue = styled.div`
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
`;
